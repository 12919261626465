import Header from "../components/header";
import Footer from "../components/footer";

export default function FindUs(){
    return(
        <main className="flex flex-col h-screen">
            <Header/>
            <div className="flex-grow overflow-y-auto flex-1">
                <div className="flex flex-1 justify-center">
                    <iframe className="w-full h-[500px]"
                      src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d3970.8707688697195!2d-75.820437!3d5.586112!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwMzUnMTAuMCJOIDc1wrA0OScxMy43Ilc!5e0!3m2!1sen!2sus!4v1700624565232!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                      title="Google Maps"/>
                </div>
            </div>
            <Footer/>
        </main>
    );
}