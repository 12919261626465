import Header from "../components/header";
import Footer from "../components/footer";
import Review from "../components/review"
import Slider from "../components/slider"
import { useTranslation } from 'react-i18next';

export default function Visitors() {
    const { t, i18n } = useTranslation();

    const reviews = [
        "https://maps.app.goo.gl/qNgYZHdn5b9DtFH79",
        "https://maps.app.goo.gl/QyqcAoW4MnDB5BiE6",
        "https://maps.app.goo.gl/8swJP5b1wsKFPgxt7",
        "https://maps.app.goo.gl/38qdTuhccjJ5XDzY9",
        "https://maps.app.goo.gl/ZNUaLxXHGREFgrHKA",
        "https://maps.app.goo.gl/8yGpFnRRyLAu74us9",
        "https://maps.app.goo.gl/ZbUSymToQtp5XdHZA",
    ];
    return (
        <main>
            <Header />
            <div>
                <Slider>
                    {[
                        ...reviews.map((review, index) => <Review key={index} source={review} />)
                    ]}
                </Slider>
            </div>
            <div className="grid flex-1 justify-center lg:mx-20 mx-10 mt-20 mb-3">
                <a className="text-3xl font-sans text-blue-600" href="https://g.page/r/Cdjvgya7xY69EAE/review">{t('leave_review')}</a>
            </div>
            <Footer />
        </main>
    );
}