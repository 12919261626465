import { useState, useEffect } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"

export default function Slider({
    children
}) {
    const [curr, setCurr] = useState(0)
    const prev = () =>
        setCurr((curr) => (curr === 0 ? children.length - 1 : curr - 1))
    const next = () =>
        setCurr((curr) => (curr === children.length - 1 ? 0 : curr + 1))

    return (
        <div className="overflow-hidden relative z-0">
            <div className="flex transition-transform ease-out duration-500" style={{ transform: `translateX(-${curr * 100}%)` }}>
                {children}
            </div>
            <button
                onClick={prev}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white left-0">
                <ChevronLeft size={40} />
            </button>
            <button
                onClick={next}
                className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white absolute right-0">
                <ChevronRight size={40} />
            </button>
            <div className="relative bottom-4 right-0 left-0">
                <div className="flex items-center justify-center gap-2">
                    {children.map((_, i) => (
                        <div
                            key={i}
                            className={`transition-all w-3 h-3 bg-white rounded-full
                                    ${curr === i ? "p-2" : "bg-opacity-50"}
                                    `} />
                    ))}
                </div>
            </div>
        </div>
    )
}