import React, { Fragment, useState } from 'react'
import { FaAngleDown } from "react-icons/fa";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from "react-flags-select";
import resources from "./translations";

i18n
    .use(initReactI18next) // Pass the i18n instance to react-i18next
    .init({
        resources, // Your translations
        lng: 'es', // Default language
        fallbackLng: 'en', // Fallback language if translation is missing
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default function Header() {
    const changeCountry = (country_code) => {
        const languages = new Map([
            ["BE", "fr"],
            ["CO", "es"],
            ["ES", "es"],
            ["FR", "fr"],
            ["GB", "en"],
            ["US", "en"],
        ]);
        setCountry(country_code);
        i18n.changeLanguage(languages.get(country_code));
    };
    const [showDropDown, setShowDropDown] = useState(false);
    const [country, setCountry] = useState("CO");

    const { t, i18n } = useTranslation();

    const options = [
        // { title: '+57(314)7447277', href: 'tel:+57%28314%297447277' },
        { title: t('find_us'), href: '/encuentranos' },
        { title: t('visitors'), href: '/visitors' },
        { title: t('reservations'), href: '/reservas' },
        { title: t('photos'), href: '/fotos' },
    ];
    return (
        <Fragment>
            <header className="bg-gradient-to-r from-stone-900 to-stone-600 z-50">
                <nav className="mx-auto flex max-w-7xl items-center p-6 lg:px-8" aria-label="Global">
                <a  className="left-0 px-4 text-lg text-white font-semibold hover:opacity-70"  href='/'>Finca Hostal Los Girasoles</a>,
                <div className="flex flex-1 justify-end">
                    <div className="relative lg:block hidden">
                            {options.map((option, index) => (
                                <a key={index} href={option.href} className="px-4 text-lg text-white font-semibold hover:opacity-70">
                                    {option.title}
                                </a>
                            ))}
                            <ReactFlagsSelect
                                fullWidth={false}
                                countries={["CO", "ES", "US", "GB", "FR"]}
                                selected={country}
                                onSelect={(code) => changeCountry(code)}
                            />
                        </div>
                        <div className="relative lg:hidden">
                            <button type="button" className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-white hover:opacity-70" aria-expanded="false" onClick={() => { setShowDropDown(!showDropDown) }}>
                            &#9776;
                                <FaAngleDown />
                            </button>
                            {showDropDown &&
                                (<div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 bg-neutral-700">
                                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        {options.map((option, index) => (
                                            <a key={index} href={option.href} className="block px-4 py-2 text-sm text-white hover:opacity-50" role="menuitem">
                                                {option.title}
                                            </a>
                                        ))}
                                        <ReactFlagsSelect
                                            fullWidth={false}
                                            countries={["BE", "CO", "US", "GB", "FR"]}
                                            selected={country}
                                            onSelect={(code) => changeCountry(code)}
                                        />
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
}
