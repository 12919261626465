import './index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router";
import Home from './views/home';
import FindUs from './views/findus';
import Photos from './views/photos';
import Reserve from './views/reserve'
import Visitors from './views/visitors'

export default function App(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
          {/* <Route path="*" element={<Home />} /> */}
        <Route path="/encuentranos" element={<FindUs />} />
        <Route path="/reservas" element={<Reserve />} />
        <Route path="/fotos" element={<Photos />} />
        <Route path="/visitors" element={<Visitors />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
