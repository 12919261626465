import Header from "../components/header";
import Footer from "../components/footer";
import Slider from "../components/slider"
import { useTranslation } from 'react-i18next';

export default function Home() {
    const { t, i18n } = useTranslation();

    const slides = [
        "./img/home/frente01.jpg",
        "./img/home/frente02.jpg"
    ];

    return (
        <main className="flex flex-col h-screen">
            <Header />
            <div className="flex-grow overflow-y-auto flex-1">
                <div className="m-auto lg:w-[900px] w-[350px] mb-5">
                    <Slider>
                        {[
                            ...slides.map((slide, index) => <img alt="front images" key={index} src={slide} />)
                        ]}
                    </Slider>
                </div>
                <div className="flex flex-1 justify-center mx-10">
                    <span className="text-xl font-sans">{t('invitation')}</span>
                </div>
                <div>
                    <div className="grid flex-1 justify-center lg:mx-20 mx-10 mt-20 mb-3">
                        <span className="text-3xl font-sans text-lime-600">{t('adventure')}</span>
                    </div>
                    <div className="grid flex-1 justify-center lg:mx-20 mx-10 mb-20">
                        <span className="text-xl font-sans">{t('girasoles_is')}</span>
                    </div>
                </div>
                <div>
                    <div className="grid flex-1 justify-center lg:mx-20 mx-10 mt-20 mb-3">
                        <span className="text-3xl font-sans text-lime-600">{t('we_have')}</span>
                    </div>
                    <div className="grid flex-1 justify-center lg:mx-20 mx-10 mb-20">
                        <span className="text-xl font-sans">{t('five_rooms')}</span>
                    </div>
                </div>
                <div>
                    <div className="grid flex-1 justify-center lg:mx-20 mx-10 mt-20 mb-3">
                        <span className="text-3xl font-sans text-lime-600">{t('services')}</span>
                    </div>
                    <div className="grid flex-1 justify-left lg:mx-20 mx-10 mb-20">
                        <ul className="list-disc text-xl font-sans pl-7">
                            <li>{t('private_rooms')}</li>
                            <li>{t('free_wifi')}</li>
                            <li>{t('breakfast')}</li>
                            <li>{t('bird_watching')}</li>
                            <li>{t('hiking')}</li>
                            <li>{t('rest_area')}</li>
                            <li>{t('laundry_service')}</li>
                            <li>{t('shared_kitchen')}</li>
                            <li>{t('free_parking')}</li>
                        </ul>
                    </div>
                </div>
                <div className="grid flex-1 justify-center lg:mx-20 mx-10 my-20">
                    <span className="text-3xl font-sans text-lime-600">{t('explore_and')}</span>
                </div>
                <div className="flex flex-1 justify-center">
                    <img alt="logo" src="img/logo.svg" className="w-[800px]" />
                </div>
                <Footer />
            </div>
        </main>
    );
}
