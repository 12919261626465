const resources = {
    en: {
        translation: {
            adventure: "Adventure starts here",
            bird_watching: "Bird Watching",
            birds: "Birds",
            breakfast: "Breakfast",
            callus: "Call Us",
            consult: "Check",
            english: "English",
            explore_and: "Explore and enjoy the unique experience of living surrounded by nature",
            five_rooms: "5 bedrooms, all with private bathrooms, a kitchen and a rest area open to all guests. Wide corridors on the second floor to watch the birds of the area. Another attraction is the crystal-clear stream along with a small waterfall located within the property to cool off on hot days.",
            find_us: "Find us",
            free_parking: "Free parking",
            free_wifi: "Free WIFI",
            french: "French",
            get_email: "Get email",
            girasoles_is: "Los Girasoles is a colorful farm-hostel surrounded by beautiful gardens, trees and leafy banana trees, where you can observe tropical birds",
            house_exterior: "House Exterior",
            house_interior: "House Interior",
            hiking: "Hiking",
            invitation: "We are waiting for you in the outskirts of Jardín, Antioquia where you can rest and enjoy nature",
            laundry_service: "Laundry service",
            leave_review: "Leave your own review",
            photos: "Photos",
            private_rooms: "Private rooms",
            property: "Property",
            reservations: "Reservations",
            reservation_confirmation: "The reservation for the selected dates was made correctly with the following confirmation number:",
            rest_area: "Rest areas",
            select_dates: "Select the stay days",
            services: "Services",
            shared_kitchen: "Shared kitchen",
            spanish: "Spanish",
            sunflowers: "Sunflowers",
            visitors: "Visitors",
            we_have: "We have"
        },
    },
    es: {
        translation: {
            adventure: "La aventura empieza aquí",
            bird_watching: "Avistamiento de aves",
            birds: "Aves",
            breakfast: "Desayuno",
            callus: "Llamanos al",
            consult: "Consultar",
            english: "Ingles",
            explore_and: "Explora y disfruta de la experiencia única de vivir rodeado de la naturaleza",
            find_us: "Encuentranos",
            five_rooms: "5 habitaciones, todas con baño privado, una cocina y área de descanso abierta para todos los huespedes. Amplios corredores en la segunda planta para avistar las aves de la zona. Otro atractivo es la quebrada de agua cristalina junto con una pequeña cascada ubicada dentro de la finca para poder refrescarse en los días calurosos",
            free_parking: "Parqueadero gratuito",
            free_wifi: "WIFI Gratuito",
            french: "Frances",
            get_email: "Recibir email",
            girasoles_is: "Los girasoles es una finca hostal llena de color y rodeada de hermosos jardines, árboles y frondosas plataneras, en donde podrás observar aves tropicales",
            hiking: "Senderismo",
            house_exterior: "Exterior de la finca",
            house_interior: "Interior de la finca",
            invitation: "Te esperamos a las afueras de Jardín, Antioquia para que descanses y disfrutes de la naturaleza",
            laundry_service: "Servicio de lavandería",
            leave_review: "Deja tus comentarios",
            photos: "Fotos",
            private_rooms: "Habitaciones privadas",
            property: "Propiedad",
            reservations: "Reservas",
            reservation_confirmation: "la reserva en las fechas seleccionadas se hizo de manera correcta con el siguiente numero de confirmación:",
            rest_area: "Zonas de descanso",
            room_reserved: "reservado correctamente",
            select_dates: "Selecciona las fechas de estadía",
            services: "Servicios",
            shared_kitchen: "Cocina compartida",
            spanish: "Español",
            sunflowers: "Girasoles",
            visitors: "Visitantes",
            we_have: "Contamos con"
        },
    },
    fr: {
        translation: {
            adventure: "L'aventure commence ici",
            bird_watching: "Observation des oiseaux",
            birds: "Oiseaux",
            breakfast: "Petit-déjeuner",
            callus: "Téléphonez Nous",
            consult: "Consulter",
            english: "Anglais",
            explore_and: "Explorez et profitez de l'expérience unique de vivre en pleine nature",
            find_us: "Trouvez-nous",
            five_rooms: "5 chambres, toutes avec salles de bain privées, une cuisine et un coin salon ouvert à tous les hôtes. De larges couloirs au deuxième étage permettent l'observation des oiseaux de la région. Une autre attraction est le ruisseau cristallin ainsi qu'une petite cascade située à l'intérieur de la ferme pour se rafraîchir lors des journées chaudes.",
            free_parking: "Parking gratuit",
            free_wifi: "WIFI gratuit",
            french: "Français",
            get_email: "Recevoir un email",
            girasoles_is: "Los Girasoles est une maison d'hôtes colorée entourée de beaux jardins, d'arbres et de bananiers feuillus, où vous pourrez observer des oiseaux tropicaux.",
            house_exterior: "L'extérieur de la ferme",
            house_interior: "Intérieur de la ferme",
            hiking: "Randonnée",
            invitation: "Nous vous attendons aux abords de Jardín, Antioquia pour que vous puissiez vous reposer et profiter de la nature",
            laundry_service: "Blanchisserie",
            leave_review: "Laissez votre propre avis",
            photos: "Photos",
            private_rooms: "Chambres privées",
            property: "Propriété",
            reservations: "Réservations",
            reservation_confirmation: "La réservation pour les dates sélectionnées a été effectuée correctement avec le numéro de confirmation suivant:",
            rest_area: "Aires de repos",
            select_dates: "Sélectionnez les dates de séjour",
            services: "Services",
            shared_kitchen: "Cuisine partagée",
            spanish: "Espagnol",
            sunflowers: "Tournesols",
            we_have: "Nous avons",
            visitors: "Visiteurs",
        },
    },
};

export default resources;

/*
export default function GetTranslations() {
    return translation_map;
}
*/
