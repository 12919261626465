import Header from "../components/header";
import Footer from "../components/footer";
import Slider from "../components/slider"
import { useTranslation } from 'react-i18next';
import { InstagramEmbed } from 'react-social-media-embed';

export default function Photos() {
    const { t, i18n } = useTranslation();

    const slidesGirasoles = [
        "./img/fotos/girasoles/Girasoles1.jpeg",
        "./img/fotos/girasoles/Girasoles2.jpeg",
        "./img/fotos/girasoles/Girasoles3.jpeg",
        "./img/fotos/girasoles/Girasoles4.jpeg"
    ];

    const slidesExterior = [
        "./img/fotos/exterior/photo00.jpg",
        "./img/fotos/exterior/photo01.jpg",
        "./img/fotos/exterior/photo02.jpg",
        "./img/fotos/exterior/photo03.jpg",
        "./img/fotos/exterior/photo04.jpg"
    ];

    const slidesInterior = [
        "./img/fotos/interior/photo07.jpg",
        "./img/fotos/interior/photo08.jpg",
        "./img/fotos/interior/photo09.jpg",
        "./img/fotos/interior/photo10.jpg",
        "./img/fotos/interior/photo11.jpg",
        "./img/fotos/interior/photo12.jpg",
        "./img/fotos/interior/photo13.jpg",
        "./img/fotos/exterior/photo14.jpg",
        "./img/fotos/interior/photo15.jpg",
        "./img/fotos/interior/photo16.jpg",
    ];

    return (
        <main className="flex flex-col h-screen">
            <Header />
            <div className="flex-grow overflow-y-auto flex-1">
                <div className="flex flex-1 justify-center mt-3 mb-3">
                    <img alt="aviso entrada" src="img/fotos/exterior/photo05.jpg" />
                </div>
                <div className="grid lg:grid-cols-2 gap-2">
                    <div>
                        <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                            <span className="text-4xl font-sans text-lime-600">{t('house_exterior')}</span>
                        </div>
                        <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                            <div className="m-auto h-auto xl:w-[600px] w-[350px] mb-5">
                                <Slider>
                                    {[
                                        ...slidesExterior.map((slide) => <img alt="" className="object-cover w-full h-full" src={slide} />)
                                    ]}
                                </Slider>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                            <span className="text-4xl font-sans text-lime-600">{t('house_interior')}</span>
                        </div>
                        <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                            <div className="m-auto xl:w-[600px] w-[350px] mb-5">
                                <Slider>
                                    {[
                                        ...slidesInterior.map((slide) => <img alt="" src={slide} />)
                                    ]}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                    <span className="text-4xl font-sans text-lime-600">{t('sunflowers')}</span>
                </div>
                <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                    <div className="m-auto w-[350px] mb-5">
                        <Slider>
                            {[
                                ...slidesGirasoles.map((slide) => <img alt="" src={slide} />)
                            ]}
                        </Slider>
                    </div>
                </div>
                <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                    <span className="text-4xl font-sans text-lime-600">{t('birds')}</span>
                </div>
                <div className="grid flex-1 justify-center lg:mx-20 mt-2 mb-3">
                    <div className="m-auto w-[350px] mb-5">
                        <InstagramEmbed url="https://www.instagram.com/p/DGcFamosX0F" />
                    </div>
                </div>
                <div className="flex flex-1 justify-center mb-3">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rwxbF6907fo?si=RAcfqUGD-9LM7MXP" title="Vista Area" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                </div>
                <Footer />
            </div>
        </main>
    );
}