import Header from "../components/header";
import Footer from "../components/footer";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

/*
The main flow in the this view is as follows:

1. The user selects dates using the calendar widget
2. The event handler listRooms is triggered when the user clicks the "Consultar" button
3. The list of rooms returned by the server is presented
4. The paypal paywidet is shown after the user press the "Reservar" button
5. When the user selects one of the paypal option the function createOrderHandler is called, this trigger two backend calls:
5.a Place a hold on the room.
5.b Tell the backend to create the order with paypal
6. This will trigger the paypal widget to capture payment order. Please use any of the fake data from https://developer.paypal.com/tools/sandbox/card-testing/ to complete the next step
7. Once the user successuflly approves payment the handler onApproveHandler is called.
*/
export default function Reserve() {
  const [alertBody, setAlertBody] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [dateRange, setDateRange] = useState([])
  const [quantityPeople, setQuantityPeople] = useState(1)
  const [infoRooms, setInfoRooms] = useState(false)
  const [roomsAvailable, setRoomsAvailable] = useState([])
  const [roomSelected, setRoomSelected] = useState()
  const [showAlertSuccess, setShowAlertSuccess] = useState(false)
  const [showAlertWarning, setShowAlertWarning] = useState(false)
  const [emailSended, setEmailSended] = useState(false)
  const [successfulTransaction, setSuccessfulTransaction] = useState(false)
  const [infoTransaction, setInfoTransaction] = useState({
    name: '',
    idTransaction: '',
    payerId: '',
    email: ''
  })
  const imageRooms = [
    { room: 'gulungu', src: './img/fotos/interior/photo13.jpg' },
    { room: 'eufonia', src: './img/fotos/interior/photo12.jpg' },
    { room: 'tucaneta', src: './img/fotos/interior/photo11.jpg' },
    { room: 'calandria', src: './img/fotos/interior/photo09.jpg' },
    { room: 'barranquero', src: './img/fotos/interior/photo08.jpg' },
  ];
  const { t, i18n } = useTranslation();
  const headerRef = useRef();

  let reservation = useRef({});
  let navigate = useNavigate();

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate('/');
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  const showAlert = (error, title, body) => {
    setShowAlertWarning(true);
    setAlertTitle(title);
    setAlertBody(body);
    setTimeout(
      () => {
        setShowAlertWarning(false)
        setAlertTitle('')
        setAlertBody('')
      },
      3000);
  }

  const bookRoom = async (room) => {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + '/.netlify/functions/book_room',
      {
        body: JSON.stringify({
          room: room,
          startDate: dateRange[0],
          endDate: dateRange[1]
        }),
        method: 'POST',
      });
    return await response.json();
  }

  const cancelRoom = async (room, reservation_id) => {
    const response = await fetch(
      process.env.REACT_APP_BACKEND + '/.netlify/functions/cancel_room',
      {
        body: JSON.stringify({
          reservation: reservation_id,
          room: room,
          startDate: dateRange[0],
          endDate: dateRange[1]
        }),
        method: 'POST',
      });
    return await response.json();
  }

  const createOrderHandler = async () => {
    try {
      reservation.current = await bookRoom(roomSelected.room);
      const response = await fetch(
        process.env.REACT_APP_BACKEND + '/.netlify/functions/create_order',
        {
          body: JSON.stringify({
            reservationId: reservation.current,
            quantity: quantityPeople
          }),
          method: "POST",
        });
      const orderData = await response.json();
      if (orderData.id) {
        setTimeout(() => {
          setShowAlertWarning(true)
          setAlertTitle('Reserva Incompleta')
          setAlertBody(`Han pasado 15 minutos sin completar la reserva. Se recargará la página`)
          setTimeout(() => {
            setShowAlertWarning(false)
            setAlertTitle('')
            setAlertBody('')
            navigate('/');
          }, 3000)
        }, 870000) // Less than 15 Minutes
        return orderData.id;
      } else {
        throw new Error(orderData);
      }
    } catch (error) {
      showAlert(
        error,
        'Error en inicializar PayPal',
        `Could not initiate PayPal Checkout...${error}`);
    }
  }

  const disableDatesBeforeToday = ({ date }) => {
    const today = new Date()
    return date <= today.setDate(today.getDate() - 1)
  }

  const handleDateChange = (date) => {
    setDateRange(date)
  }

  const handleQuantityChange = (value) =>{
    let newValue = Math.max(1, Math.min(30,value.target.value))
    setQuantityPeople(newValue)
  }

  const listRooms = async () => {
    // console.log(headerRef.current.getCurrency());
    if (dateRange.length !== 2) {
      showAlert('', 'Rango de fechas inválido', 'Selecciona un rango de fechas válido');
      return;
    }

    await fetch(
      process.env.REACT_APP_BACKEND + '/.netlify/functions/list_rooms',
      {
        body: JSON.stringify({
          startDate: dateRange[0],
          endDate: dateRange[1]
        }),
        method: 'POST',
      }).then(response => response.json().then(data => {
        if (data.length === 0) {
          showAlert('', 'Sin disponibilidad', 'No hay habitaciones disponibles en el rango seleccionado');
        } else {
          setRoomsAvailable(data)
          setInfoRooms(true)
        }
      }));
  }


  const onApproveHandler = async (data, actions) => {
    try {
      reservation.current.orderId = data.orderID;
      reservation.current.quantity = quantityPeople;
      const response = await fetch(
        process.env.REACT_APP_BACKEND + '/.netlify/functions/capture_order',
        {
          body: JSON.stringify(reservation.current),
          method: "POST",
        });

      const orderData = await response.json();
      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        return actions.restart();
      } else if (errorDetail) {
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else if (!orderData.purchase_units) {
        throw new Error(response);
      } else {
        const transaction =
          orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
          orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];
        setSuccessfulTransaction(true)
        setInfoTransaction({
          name: orderData.payer.name.given_name,
          idTransaction: orderData.id,
          payerId: orderData.payer.payer_id,
          email: orderData.payer.email_address
        });
        setShowAlertSuccess(true)
        setAlertTitle('Transacción exitosa')
        setAlertBody(`Transaction ${transaction.status}: ${transaction.id}`)
        setTimeout(() => {
          setShowAlertSuccess(false)
          setAlertTitle('')
          setAlertBody('')
        }, 3000)
      }
    } catch (error) {
      showAlert(error,
        'Error en inicializar PayPal',
        `Sorry, your transaction could not be processed...${error}`);
    }
  }

  const onCancelHandler = async (data, actions) => {
    reservation.current = await cancelRoom(roomSelected.room, reservation.current);
  }

  const onErrorHandler = async (error) => {
  }


  const sendEmail = async () => {
    if (!emailSended) {
      var request = {
        destinyEmail: infoTransaction.email,
        toName: infoTransaction.name,
        room: roomSelected.room,
        date: dateRange[0].toLocaleDateString()
      }
      const response = await emailjs.send('default_service', 'template_mh1f8yk', request, 'mTTI0CUrbFLdTbsmv');
      if (response.status === 200) {
        setEmailSended(true);
        setShowAlertSuccess(true)
        setAlertTitle('Correo enviado')
        setAlertBody("El correo ha sido enviado")
        setTimeout(() => {
          setShowAlertSuccess(false)
          setAlertTitle('')
          setAlertBody('')
        }, 3000)
      }
    }
  }

  return (
    <main className="flex flex-col h-screen">
      <Header ref={headerRef}/>
      { /* Alerts */}
      {showAlertWarning && <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
        <p className="font-bold">{alertTitle}</p>
        <p>{alertBody}</p>
      </div>}
      {showAlertSuccess && <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
        <p className="font-bold">{alertTitle}</p>
        <p>{alertBody}</p>
      </div>}
      <div className="flex-grow overflow-y-auto flex-1">
        <div className="grid gap-2">
          {/* Selector de fechas disponibles */}
          {!infoRooms &&
            <div className="grid flex-1 justify-center mt-2 mb-3">
              <span className="text-2xl text-center font-sans text-lime-600 mb-3">
                Selecciona las fechas de estadía
              </span>
              <div className="grid justify-start my-2 ml-2">
                <label>
                  Cantidad de personas: 
                  <input
                    type="number"
                    className="ml-2 w-12 border border-black pl-1 rounded"
                    value={quantityPeople} 
                    onChange={handleQuantityChange} 
                    min={1} 
                    max={30} 
                    step={1} 
                  />
                </label>
              </div>
              <Calendar onChange={handleDateChange}
                value={dateRange || []}
                tileDisabled={disableDatesBeforeToday}
                selectRange />
              <div className="grid justify-end mt-4">
                <button type="button" onClick={listRooms} className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {t('consult')}
                </button>
              </div>
            </div>}
          { /* Habitaciones disponibles */}
          {infoRooms && roomSelected === undefined && roomsAvailable.map((option, index) => (
            <div key={index} className="grid flex-1 justify-center">
              <span className="mt-2 text-2xl text-center font-sans text-lime-600">
                Cuarto {option.room}
              </span>
              <div className="flex flex-1 justify-center mt-3">
                <img alt="cuarto" src={imageRooms.find(r => r.room === option.room).src} className="w-[400px] md:w-[700px]" />
              </div>
              <div className="grid justify-end mt-4">
                <button type="button" onClick={() => setRoomSelected(option)} className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Reservar
                </button>
              </div>
            </div>
          ))}
          {/* Pasarela de pago */}
          {roomSelected !== undefined && !successfulTransaction &&
            <div className="mt-16 grid grid-cols-3 justify-center">
              <div></div>
              <div className="App">
                <PayPalScriptProvider options={{
                  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID
                }}>
                  <PayPalButtons
                    style={{
                      shape: "rect",
                      layout: "vertical"
                    }}
                    createOrder={createOrderHandler}
                    onApprove={onApproveHandler}
                    onCancel={onCancelHandler}
                    onError={onErrorHandler}
                  />
                </PayPalScriptProvider>
              </div>
            </div>
          }
          {/* Informacion de transaccion exitosa */}
          {roomSelected !== undefined && successfulTransaction &&
            <div className="grid flex-1 justify-center mt-8 mb-3">
              <span className="text-2xl text-center font-sans text-lime-600">
                Cuarto {roomSelected.room} {t('room_reserved')}
              </span>
              <span className="text-2xl text-center font-sans mt-2">
                {infoTransaction.name}, {t('reservation_confirmation')}{infoTransaction.idTransaction}
              </span>
              <div className="grid flex-1 mt-4 justify-center">
                <button type="button" onClick={sendEmail} className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {t('get_email')}
                </button>
              </div>
            </div>
          }
        </div>
        {infoRooms && roomSelected === undefined && <Footer />}
      </div>
      {(!infoRooms || roomSelected !== undefined) && <Footer />}
    </main>
  );
}
