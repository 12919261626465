import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <Fragment>
            <footer className="bg-gradient-to-r from-stone-900 to-stone-600">
                <div className="flex flex-1 justify-center">
                    <a href='tel:+57%28314%297447277'><span className='text-white mb-2 text-xl'>{t('callus')} +57(314)7447277</span></a>
                </div>
                <nav className="mx-auto flex max-w-7xl items-center justify-between p-3 lg:px-8" aria-label="Global">
                    <div className="flex flex-1 justify-center">
                        <a href="https://www.hostelworld.com/pwa/hosteldetails.php/Finca-Los-Girasoles/Jardin/316655" rel="noreferrer" target="_blank">
                            <img alt="" src="img/logo/hostelWorld.png" className='w-[50px] mx-4' />
                        </a>
                        <a href="https://www.instagram.com/girasolesfinca/" rel="noreferrer" target="_blank">
                            <img alt="" src="img/logo/instagram.png" className='w-[50px] mx-4' />
                        </a>
                        <a href="https://www.airbnb.com/rooms/42241379" rel="noreferrer" target="_blank">
                            <img alt="" src="img/logo/airbnb.png" className='w-[50px] mx-4' />
                        </a>
                        <a href="https://api.whatsapp.com/send?phone=573147447277" rel="noreferrer" target="_blank">
                            <img alt="" src="img/logo/whatsApp.png" className='w-[50px] mx-4' />
                        </a>
                    </div>
                </nav>
                <div className="flex flex-1 justify-center">
                    <span className='text-white mb-2 text-xs'>Km 1.6 Vereda Serranias, Jardin, Colombia</span>
                </div>
            </footer>
        </Fragment>
    )
}