import React, { useState, useEffect } from 'react';

export default function Review({
  source = "",
}) {
  const [review, setReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getReview = async (review) => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND + '/.netlify/functions/get_review',
          {
            body: JSON.stringify({
              review_src: review,
            }),
            method: 'POST',
          });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`); // Handle HTTP errors
        }
        const jsonData = await response.json();
        setReview(jsonData); // Set the data
        setError(null); // Clear any previous errors
      } catch (err) {
        setError(err); // Set the error
        setReview(null); // Clear any previous data
        console.error('Error fetching data:', err); // Log the error for debugging
      } finally {
        setLoading(false); // Set loading to false after fetch completes (success or error)
      }
    };

    getReview(source); // Call the fetch function

  }, []); // Empty dependency array: fetch only once on mount

  return (
    <div className='min-w-full'>
      {review && review.title && <div>
        <div className="grid flex-1 justify-center lg:mx-20 mx-10 mt-20 mb-3">
          <a className="text-3xl font-sans text-lime-600" href={source}> {review.title}</a>
        </div>
        <div className="grid flex-1 justify-center lg:mx-20 mx-10 mb-20">
          <span className="text-xl font-sans">{review.review}</span>
        </div>
      </div>}
    </div>
  );
}
